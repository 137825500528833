//Angular Imports 
import { Injectable } from "@angular/core";
import { Observable, Subscriber, of, throwError } from "rxjs";
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Dictionary, forEach, List } from "lodash";
import { catchError, map } from "rxjs/operators";
import { CPMElement } from "../models";
import { ElementValueSource, SkippableInterceptorHeader } from "../enums";
import { BYPASS_URL_APPEND } from "../interceptors";
import { IssuanceLog, TestHarnessLogWithEnv } from "../../diagnostics/diagnostics-main/models/diagnosticLog";
import { RuleEngineLog } from "../../diagnostics/testSuites/execution/models/rule-engine-log.model";
import { InsightHubLog } from "../models/insighthublog.model";
import { EnvironmentService } from "./environment.service";
import { QueueLog } from "../../ghostPortal/queue/models/queueLog";
import { OdenData } from "../models/oden-data.model";
import { SplashPageInput } from "../models/splashPageInput";
//import { RuleEngineResult } from "../../execution/models/rule-engine-result.model";

/**
 * Provider shared across the whole app
*/
@Injectable({
  providedIn: 'root'
})

export class SharedService {

  //Private variables
  /**
   * loader selector name 
  */
  private _selector: string;
  /**
   * preloader selector name 
  */
  private _preloader: string;

  /**
 * Variable to make sure that the spinner is not hidden when multiple calls are fired and only one has returned
*/
  private _callTracker: number;

  /**
   * MasterData
   */
  private _masterData: any;

  /**
   * MasterDataCPMElement
   */
  private _masterDataFromCPMElement: any;

  /**
 * Master data with e2 descriptions
 */
  private _masterDataFromCPMElementForE2: any;

  /**
   * AllCPMElementsData
   */
  private _allCPMElementsData: any;

  globalTestResults: any;
  globalTestRunStatus: string;
  globalTestRunResultId: string;

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this._selector = "loader";
    this._preloader = "preloader";
    this._callTracker = 0;
  }


  /**
  * Display the loader
  * 
  * @returns void
  */
  public showLoader(): void {
    this.showSpinner();
    this._callTracker++;
  }

  /**
  * Hides the loader 
  * 
  * @returns {void}
  */
  public hideLoader(): void {
    this._callTracker--;
    this.hideSpinner();
  }

  public incrementCallTracker(): void {
    this._callTracker++;
  }

  public decrementCallTracker(): void {
    this._callTracker--;
  }

  /**
  * Hides the preloader 
  * 
  * @returns {void}
  */
  public hidePreloader(): void {
    document.getElementById(this._preloader).style['display'] = 'none';
  }

  //Private Methods
  /**
  * Shows the spinner
  * 
  * @returns {void}
  */
  private showSpinner(): void {
    document.getElementById(this._selector).style['display'] = 'block';
  }

  /**
  * Hides the spinner
  * 
  * @returns void
  */
  private hideSpinner(): void {
    //if (this._callTracker == 0) {
      document.getElementById(this._selector).style['display'] = 'none';
    //}
  }

  /**
  * Method to Get master data
  * 
  */
  getMasterData(): Observable<any> {
    //TODO: Remove later
    if (this._masterData) {
      return of(this._masterData);
    }
    else {
        let url = `api/MasterData`;
        return this.http.get<any>(url).pipe(map(res => {
          this._masterData = res;
          return res;
        })
        );
    }

  }

  /**
  * Method to Get master data
  * 
  */
  getMasterDataFromCpmElement(cpmElementId: string): Observable<any> {
    if (this._masterDataFromCPMElement && this._masterDataFromCPMElement[cpmElementId]) {
      return of(this._masterDataFromCPMElement[cpmElementId]);
    }
    else {
        let url = `api/MasterData/CpmElement/${cpmElementId}`;
      return this.http.get<any>(url).pipe(map(res => {
        if (!this._masterDataFromCPMElement) {
          this._masterDataFromCPMElement = {};
        }
          this._masterDataFromCPMElement[cpmElementId] = res;
          return res;
        })
        );
    }

  }

  /**
  * Method to Get master data for specified CPM Element, with descriptions for specified value source
  * either CPM or E2
  * 
  */
  getMasterDataFromCpmElementByValueSource(cpmElementId: string, valueSource: ElementValueSource): Observable<any> {
    if (valueSource === ElementValueSource.CPM) {
      return this.getMasterDataFromCpmElement(cpmElementId);
    }
    else if (valueSource === ElementValueSource.E2) {
      // return e2 descriptions
      if (this._masterDataFromCPMElementForE2 && this._masterDataFromCPMElementForE2[cpmElementId]) {
        return of(this._masterDataFromCPMElementForE2[cpmElementId]);
      }
      else {
        let url = `api/MasterData/CpmElement/${cpmElementId}/${valueSource}`;
        return this.http.get<any>(url).pipe(map(res => {
          if (!this._masterDataFromCPMElementForE2) {
            this._masterDataFromCPMElementForE2 = {};
          }
          this._masterDataFromCPMElementForE2[cpmElementId] = res;
          return res;
        })
        );
      }
    }
    else {
      // Invalid valueSource
      return of(null);
    }

  }

  /**
  * Method to Get CPM Element master data
  * 
  */
  getAllCpmElements(): Observable<CPMElement[]> {
    if (this._allCPMElementsData) {
      return of(this._allCPMElementsData);
    }
    else {
      let url = `api/MasterData/CpmElements`;
      return this.http.get<any>(url).pipe(map(res => {
        if (!this._allCPMElementsData) {
          this._allCPMElementsData = {};
        }
        this._allCPMElementsData = res;
        return res;
      })
      );
    }

  }

  getAllCpmElementsRulesEngine(): Observable<CPMElement[]> {
    let url = `api/MasterData/CpmElementsRulesEngine`;
    return this.http.get<any>(url).pipe(map(res => {
      if (!this._allCPMElementsData) {
        this._allCPMElementsData = {};
      }
      this._allCPMElementsData = res;
      return res;
    }));
  }

  createGuid() {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  checkAttachAPIHealth() {
    let url = `https://mkl-fast-prod-api.azurewebsites.net/health`;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    return this.http.get(url, { headers, responseType: 'text', context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }

  checkGenAPIHealth() {
    let url = `https://mkl-mgdapi-prod.azurewebsites.net/api/v1/document/ping`;
    var headers = new HttpHeaders();
    return this.http.get(url, { headers, responseType: 'text', context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }

  checkQueueAPIHealth() {
    let url = `https://mkl-doc-queue-uat2.azurewebsites.net/health`;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    return this.http.get(url, { headers, responseType: 'text', context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }

  getTransactionId(polNumber: string, environment: string): Observable<any[]> {
    let url = ``;
    if (environment != "PROD") {
      url = `https://extension-${environment.toLowerCase()}.azurewebsites.net/api/transaction/policy?policyNumber=${polNumber}`;
    }
    else {
      url = `https://mna-use2-prod-fd-gde-01.azurefd.net/api/transaction/policy?policyNumber=${polNumber}`;
    }
    // let url = `http://localhost:53001/api/transaction/policy?policyNumber=${polNumber}`;
    var headers = new HttpHeaders();
    headers.append('accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }

  /*mdoHealthMeter(): Observable<any> {
    let getUrl = 'api/queue/health-metrics';
    return this.http.get<any>(getUrl, { observe: "response", headers: this.generateRequestHeaders() })
      .pipe(
        map(res => {
          // Handle a success response
          return res.body.Table;
        }),
        // Handle an error response
        catchError(this.handleHTTPError)
      );
  }*/

  handleHTTPError(error: HttpErrorResponse) {
    return throwError(error);
  }

  generateRequestHeaders(mockHeaderValue?: string): HttpHeaders {
    let headers = new HttpHeaders();
    // Because HttpHeaders is immutable, any action to add or alter a header returns a new instance that has to be reassigned to the variable
    headers = headers.set(SkippableInterceptorHeader.ShowLoaderSkipHeader, "true");
    if (mockHeaderValue) {
      headers = headers.set("x-mock-response", mockHeaderValue);
    }
    return headers;
  }

  searchExtensionLogs(env: string, dealNumber: string): Observable<IssuanceLog[]> {
    let url = `https://extension-${env.toLowerCase()}.azurewebsites.net/api/logs/${dealNumber}`;
    if (env == "PROD")
      url = `https://mna-use2-prod-fd-gde-01.azurefd.net/api/logs/${dealNumber}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }
  getQueueStatus(transactionId: string): Observable<any> {
    var url = `https://mkl-doc-queue-uat2.azurewebsites.net/api/storage/search?dealNumber=${transactionId}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }

  getQueueStatusSingle(dealNumber: string, env: string): Observable<QueueLog> {
    var url = `https://mkl-doc-queue-${env.toLowerCase()}.azurewebsites.net/api/storage/status?dealNumber=${dealNumber}&environment=${env}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }

  getLogsByDealNumber(dealNumber: string): Observable<RuleEngineLog[]> {
    let url = `api/RuleEngineLog?dealNumber=` + dealNumber;
    return this.http.get<RuleEngineLog[]>(url).pipe(
      map(res => res)
    );
  }

  getSingleLog(dealNumber: string, sourceLogID: string): Observable<RuleEngineLog[]> {
    let url = `api/RuleEngineLog/Details?dealNumber=${dealNumber}&sourceLogID=${sourceLogID}`;
    return this.http.get<RuleEngineLog[]>(url).pipe(
      map(res => res)
    );
  }

  getOdenLogs(): Observable<OdenData[]> {
    let url = `api/RuleEngineLog/Oden`;
    return this.http.get<OdenData[]>(url).pipe(
      map(res => res)
    );
  }

  getJwt(env: string) {
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getExtensionJWT() {
    var gurl = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/login`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    if (this.environmentService.environmentDetails.Environment == 'PROD') {
      headers = headers.append('Authorization', `Basic ${btoa('DocPortal:52f469C8-6C95-410C-B30E-5124EDF78CA6')}`)
    }
    else {
      headers = headers.append('Authorization', `Basic ${btoa('DocPortal:DD5065AF-2A62-4002-B08F-0EB91221FDA7')}`);
    }
    console.log(headers);
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  searchLogs(dealNumber: string, env: string, token: string): Observable<TestHarnessLogWithEnv[]> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2//logrequest/getLogs?dealNumber=${dealNumber}&isPortal=1`;
    if (env.toLowerCase() == 'local') {
      gurl = `http://localhost:63161/api/v1//logrequest/getLogs?dealNumber=${dealNumber}`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<TestHarnessLogWithEnv[]>(gurl, { headers, responseType: 'json' }).pipe(map(res => res));
  }

  getMGDLog(blobString: string, env: string, token: string): Observable<TestHarnessLogWithEnv> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2/logrequest/getSingleLog?blobString=${blobString}`;
    if (env.toLowerCase() == 'local') {
      gurl = `http://localhost:63161/api/v1//logrequest/getSingleLog?blobString=${blobString}`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get<TestHarnessLogWithEnv>(gurl, { headers, responseType: 'json', context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }

  cognitiveSearch(query: string, filters?: SplashPageInput[], sortType = 'desc'): Observable<any> {
    var url = `https://insighthub-data-search.search.windows.net/indexes/cosmosdb-index-hub/docs/search?$top=1000&api-version=2023-11-01`;
    var body = { 'search': query };

    if (filters != null && filters.length > 0) {
      let tempString = `&$filter=`;
      const filterConditions = {};

      // Group filters by Name
      for (var i = 0; i < filters.length; i++) {
        const filterName = filters[i].Name;
        const filterValue = filters[i].Value;

        if (!filterConditions[filterName]) {
          filterConditions[filterName] = [];
        }

        if (filterName === "Error") {
          if (filterValue === "Yes") {
            // Check for Error being not null
            filterConditions[filterName].push("Error ne null");
          } else if (filterValue === "No") {
            // Check for Error being null
            filterConditions[filterName].push("Error eq null");
          }
        } else {
          // Normal filter conditions for CallingSystem and SourceSystem
          filterConditions[filterName].push(`search.ismatch('${filterValue}','${filterName}')`);
        }
      }

      // Construct the final filter string
      const finalConditions = [];
      for (const name in filterConditions) {
        if (filterConditions[name].length > 0) {
          // Join conditions for the same name with 'or'
          finalConditions.push(`(${filterConditions[name].join(' or ')})`);
        }
      }

      tempString += finalConditions.join(" and ");
      url = url + tempString;
    }

    if (query.includes("*")) {
      url += "&$orderby=Timestamp " + sortType;
    }

    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('access-control-allow-origin', this.environmentService.environmentDetails.ORIGIN);
    headers = headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    headers = headers.append('api-key', 'SgEAKKZWSeHT8qDyk7QvEf6vMmD6mOv7lXZaEIObSEAzSeCISQFI');
    headers = headers.append("Access-Control-Allow-Credentials", "true");

    return this.http.post<any>(url, body, { headers, responseType: 'json', context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }

  getCPMFromKey(key: string): Observable<any> {
    let url = `api/RuleEngineLog/CPMStore?key=` + key;
    return this.http.get<any[]>(url).pipe(
      map(res => res)
    );
  }



}

//Angular Imports 
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as xml2js from 'xml2js';

//Internal Imports 
import { UserProfileService, DateFilterComponent, BooleanFilterComponent, SelectableItem } from '../../../../../framework';
import 'rxjs';
import { TestHarnessService } from '../../services/testHarness.service';
import { TestHarnessTestCase } from '../../models/testHarness-testCase.model';
import { TestHarnessTestGroup } from '../../models/testHarness-testGroup.model';
import { PolicyForm } from '../../../../../ghostPortal/formsList/models/policyForm.model';
import { coverageCpmElementId, lobCpmElementId, TestingService } from '../../../execution';
import { isNullOrUndefined } from 'util';


/**
 * Forms Component
*/
@Component({
  selector: 'app-testHarness-testCase',
  templateUrl: './testHarness-testCase.component.html',
  styleUrls: ['./testHarness-testCase.component.scss']
})

export class TestHarnessTestCaseComponent {

  //@ViewChild(TestCaseExpectedResult)
  //private expectedResultsComponent: TestCaseExpectedResult;

  @Input() public actionType: string;
  @Input() public logItem: any;
  @Input() public testCase: TestHarnessTestCase;

  testCaseCPM: string;
  testCaseWritingCompany: string;
  testCaseState: string;
  testCasePolicyEffectiveDate: number;
  testCaseLOBs: string[] = [];
  testCaseCoverages: string[] = [];
  testCaseForms: any[] = [];
  testGroupGridOptions: GridOptions;
  testGroupSelectableItems: SelectableItem[];
  testGroups: TestHarnessTestGroup[] = [];

  lobGridOptions: GridOptions;
  lobSelectableItems: SelectableItem[];
  coverageGridOptions: GridOptions;
  coverageSelectableItems: SelectableItem[];
  initialRowDataLoad$;
  public selectedTestCaseFormsGridOptions: GridOptions;

  incompleteLog:boolean =  false;

  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private harnessService: TestHarnessService,
    private userProfileService: UserProfileService,
    private testingService: TestingService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

    this.testGroupSelectableItems = [];
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.testCaseCPM = this.testCase.CPM.toString();
    if (this.testCase.Forms == null) {
      this.incompleteLog = true;
      console.log("Log has no forms in field");
    }
    else {
      this.testCaseForms = this.testCase.Forms.map(name => {
        return { name }
      });
    }
    
    
    this.configureFormsGrid();
    this.configureTestGroupGrid();
    this.configureLobGrid();
    this.configureCoverageGrid();
     

    if (this.actionType === "Add") {
      this.testCase = new TestHarnessTestCase();
      //this.testCase.CPM = this.logItem.CPM;
      this.createExpectedResults();
    }
    this.populateCpmData();

    
    

  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }


  public saveTestCase() {
    if (this.actionType == "Edit") {
      this.testCase.CPM = this.testCaseCPM;
      this.testCase.Forms = this.testCaseForms.map(function (item) {
        return item['name'];
      });
        this.saveSelectableItems();
    }
    else {
      this.testCase.DocumentType = "TestCase";
      this.testCase.CPM = this.testCaseCPM;
      this.testCase.Forms = this.testCaseForms.map(function (item) {
        return item['name'];
      });
        this.testCase.CreatedByName = this.userProfileService.UserProfile.UserName;
        this.testCase.CreationDate = moment().unix();
      console.log(this.testCase);
        this.saveSelectableItems();
    }


   // this.testingService.addTestCase(this.testCase).subscribe(res => {
    //  this.testCase = res;

      // Close the popup after saving
    //  this.activeModal.close();
   // });
  }

  public getTestGroups() {
    this.harnessService.getTestGroups().subscribe(res => {
      this.testGroups = res;
      this.testGroups.forEach(testGroup => {
        var item = new SelectableItem();
        item.Code = testGroup.id;
        item.VisibleDescription = testGroup.Name;
        if (this.actionType != "Add" && this.testCase.TestGroups.indexOf(testGroup.id) != -1) {
          item.Selected = true;
        }
        else {
          item.Selected = false;
        }
        this.testGroupSelectableItems.push(item);
      });
      console.log(this.testGroupSelectableItems);


      this.testGroupGridOptions.api.setRowData(this.testGroupSelectableItems);
      //this.selectedTestCaseFormsGridOptions.api.setRowData(this.testCaseForms);
    });
  }

  private saveSelectableItems() {
    let testGroups: string[] = [];
    for (var testGroup of this.testGroupSelectableItems) {
      if (testGroup.Selected) {
        testGroups.push(testGroup.Code);
      }
    }
    this.testCase.TestGroups = testGroups;
    console.log(this.testCase);
    this.activeModal.close(this.testCase);

    // Save the expected results currently selected and displayed in the child component back
    // to the testCase
    //this.expectedResultsComponent.saveExpectedResult();
  }

  // TODO
  importCpmForms() {
    this.testCaseForms = [];
    //If CPM Input exists initialize temp form list and parser to parse XML to JSON
    console.log(this.testCaseCPM);
    if (this.testCaseCPM != null && this.testCaseCPM.length > 1) {
      var tempFormList: any[] = [];
      var parser = new xml2js.Parser;
      parser.parseString(this.testCaseCPM, function (err, result) {
        var forms: any;
        //Pull forms list into array var
        if (result.QuoteMessage != null) {
          console.log(result);
          forms = result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0].Policy[0].Forms[0].Form;
        }
        else {
          forms = result.PolicyMessage.Policy[0].Forms[0].Form;
        }
        //Create form object and populate fields used in grid, then push to temp list
        for (var i = 0; i < forms.length; i++) {
          var form = new PolicyForm();
          form.FormName = forms[i].$.InternalFormNumber
          form.PackageName = "Imported";
          tempFormList.push(form.FormName);
        }
      });
      this.testCaseForms = tempFormList.map(name => {
        return { name }
      });
      this.selectedTestCaseFormsGridOptions.api.setRowData(this.testCaseForms);
      //Create policy form object and iterate through keys to populate all fields with default value (needed to set global list since model does not have default constructor)
      /*var obj = new PolicyForm();
      let keys = Object.keys(obj);
      let def = keys.reduce((result, key) => {
        result[key] = "";
        return result;
      }, {});
      let result = tempFormList.map((item) => ({ ...def, ...item }));
      //this.selectedFormList = result;
      //this.canCreateTest = false;
      //this.setSelectedRows();*/
    }
    else {
      this.testCase.CPM = "CPM Input tab empty, please paste a CPM here to import forms";
      //this.selectTab(0);
      return;
    }
  }

  private createExpectedResults() {

  }


  private populateCpmData(): void {
    this.testingService.getCpmDataForTestCase(this.testCaseCPM).subscribe(res => {
      //this.testCase.LOBs = res.ElementValues.LineOfBusiness;
      //this.testCase.Coverages = res.ElementValues.Coverage;
      console.log(res);
      this.testCaseWritingCompany = res.ElementValues.WritingCompany[0];
      this.testCaseState = res.ElementValues.State[0];
      this.testCasePolicyEffectiveDate = res.EffectiveDate;
      this.testCaseLOBs = res.ElementValues.LineOfBusiness;
      if (this.incompleteLog) {
        this.importCpmForms;
      }
      this.createSelectableItemLists();
    });
  }

  private sortSelectableItems(items: SelectableItem[]) {
    items.sort((a, b) => {
      if (a.Selected == b.Selected) {
        return 0;
      }
      else if (a.Selected) {
        return -1;
      }
      else
        return 1;
    });
  }

  private configureLobGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.lobGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createLobColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.lobGridOptions.api.setRowData([]);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.lobGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.lobGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  private createSelectableItemLists(): void {
    // Load LOBs
    this.testingService.getSelectableCpmElementList(lobCpmElementId).subscribe(res => {
      // foreach lob in test case, set selected item to selected
      for (var lob of this.testCaseLOBs) {
        var item = res.find(x => x.Code === lob);
        if (item) {
          item.Selected = true;
        }
      }

      this.lobSelectableItems = res;
      this.sortSelectableItems(this.lobSelectableItems);
      this.lobGridOptions.api.setRowData(this.lobSelectableItems);
      this.lobGridOptions.columnApi.autoSizeAllColumns();
    });

    // Load Coverages
    this.testingService.getSelectableCpmElementList(coverageCpmElementId).subscribe(res => {
      // foreach coverage in test case, set selected item to selected
      for (var coverage of this.testCaseCoverages) {
        var item = res.find(x => x.Code === coverage);
        if (item) {
          item.Selected = true;
        }
      }
      this.coverageSelectableItems = res;
      this.sortSelectableItems(this.coverageSelectableItems);
      this.coverageGridOptions.api.setRowData(this.coverageSelectableItems);
      this.coverageGridOptions.columnApi.autoSizeAllColumns();
    });
  }
  private createLobColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} disabled />`;
        }
      },
      { headerName: 'LOB', editable: false, field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }

  private configureCoverageGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.coverageGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createCoverageColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.coverageGridOptions.api.setRowData([]);
        //this.summaryGridOptions.api.sizeColumnsToFit();
        this.coverageGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.coverageGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };
  }

  /**
  * configure forms grid columns
  */
  private createCoverageColumnDef(): any[] {
    return [
      {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} disabled />`;
        }
      },
      { headerName: 'Coverage', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },

    ]
  }

  private configureTestGroupGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.testGroupGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createTestGroupColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.testGroupGridOptions.api.setRowData([]);
        this.testGroupGridOptions.api.sizeColumnsToFit();
        this.getTestGroups();
        //this.testGroupGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onRowSelected(event);
        this.testGroupGridOptions.api.refreshCells();
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };
  }

  private configureFormsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.selectedTestCaseFormsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createColumDef2(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        if (this.incompleteLog) {
          this.importCpmForms();
        }
        else {
          this.selectedTestCaseFormsGridOptions.api.setRowData(this.testCaseForms);
        }
        
        this.selectedTestCaseFormsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.selectedRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);

      },
      onSortChanged: (event) => {

      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };
  }

  selectedRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Remove":
          this.testCaseForms.splice(this.testCaseForms.indexOf(data), 1);
          this.selectedTestCaseFormsGridOptions.api.setRowData(this.testCaseForms);
          break;
      }
    }
  }

  private createColumDef2(): any[] {
    let result: any[] = [
      { headerName: 'Form Number', field: 'name', filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        template:
          `
         <img src="/assets/images/x_icon.png" data-action-type="Remove" class="cursor_pointer mrgrgt10" title="Remove Form">
         <img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">
         <img src="/assets/images/assemble_1.png" data-action-type="Assemble" class="cursor_pointer" title="Map and Assemble Single">
           `
      }
    ]

    return result;
  }


  private onRowSelected(event: any): void {
    //event.data.Selected = event.node.selected;

    if (event.node.selected) {
      if (event.data.Selected) {
        event.data.Selected = false;
      } else {
        event.data.Selected = true;
      }
    }
  }

  /**
  * configure forms grid columns
  */
  private createTestGroupColumnDef(): any[] {
    return [
       {
        headerName: '', field: 'Selected', maxWidth: 40, suppressFilter: true, cellRenderer: params => {
          //return `<input type='checkbox' ${params.value == true ? 'checked' : ''} />`;
          return `<input type='checkbox' data-action-type="Edit"  ${params.value ? 'checked' : ''} />`;
        }
      },
      { headerName: 'Test Group', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } }
      //{ headerName: 'Description', field: 'VisibleDescription', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } }

    ]
  }

}

//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { MigrationLogs } from "../../models/migrationLogs";

//Third Party Imports

export class resultPages {
  Results: string[];
  ContinuationToken: string;
}

/**
  * Policy Forms Provider
*/
@Injectable()
export class MigrationService {

  private masterData: any;
  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient) {
  }

  getMigrationLogs(): Observable<MigrationLogs> {
    let url = `api/RuleEngineLog/MigrationStats`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<MigrationLogs>(url).pipe(
      map(res => res)
    );
  }

  getValidationLogs(): Observable<string[]> {
    let url = `api/RuleEngineLog/Validation`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<string[]>(url).pipe(
      map(res => res)
    );

  }

  getValidationLogsAsPages(batchSize: number, continuationToken: string): Observable<resultPages> {
    let url = `api/RuleEngineLog/ValidationPages?batchsize=${batchSize}&continuationToken=${continuationToken}`;
    if (continuationToken == null) {
      url = `api/RuleEngineLog/ValidationPages?batchsize=${batchSize}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<resultPages>(url).pipe(
      map(res => res)
    );

  }

  getCSVReport(): Observable<any[]> {
    let url = `api/RuleEngineLog/GenerateValidationReport`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/csv');
    return this.http.get<string[]>(url).pipe(
      map(res => res)
    );
  }

  downloadReport(): Observable<any> {
    let url = `api/RuleEngineLog/DownloadReport`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/csv');
    headers = headers.append('Accept', 'text/csv');
    return this.http.get(url, { headers, responseType: 'text' as const }).pipe(map(res => res));
  }

  getValidationLogsDocs(): Observable<string[]>{
    let url = `api/RuleEngineLog/ValidationDocs`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<string[]>(url).pipe(
      map(res => res)
    );
  }

}

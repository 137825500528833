//Angular Imports
import { Component, HostBinding, enableProdMode, Input } from "@angular/core";
import { formatDate } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { Clipboard } from "@angular/cdk/clipboard";
//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Toast, ToastrService } from "ngx-toastr";
import * as xml2js from "xml2js";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
  SelectableItem,
} from "../../../../framework";
import {
  coverageCpmElementId,
  lobCpmElementId,
} from "../../../configs/execution-engine.config";

import { DiagnosticsMainService } from "../../services";
import { TestHarnessLogWithEnv } from "../../models/diagnosticLog";
import { PolicyForm } from "../../../../policy-forms/models/policyForm.model";
import { AttachTestLog } from "../../../models";
import { error } from "console";
import { GenerateDocumentService } from "./generate-document.service";

/**
  Main Layout Component
*/
@Component({
  selector: "genLog",
  templateUrl: "./gen-log-detail.component.html",
  styleUrls: ["./gen-log-detail.component.scss"],
  providers: [GenerateDocumentService]
})
export class GenLogDetail extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  @Input() public testLog: TestHarnessLogWithEnv;
  //Use Doc attach log to store LOBs, Coverages, other metadata from cpm
  public cpmMetadata: AttachTestLog = new AttachTestLog();

  public cpmIsXML: boolean = false;
  public formResults: any[] = [];
  public lobResults: string = "";
  public coverageResults: string = "";
  public jsonStringcopy: any;
  selectedEnv = '';

  public policyNumber: string = "";

  initialRowDataLoad$;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private diagnosticService: DiagnosticsMainService,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe,
    private clipboard: Clipboard,
    public dataService: GenerateDocumentService
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.populateCpmData();
    this.importForms();
    this.dataService.loadLog(this.testLog);


    //We grabbed the policy number from the CPM in import forms, then:
    //If has trans, populate global variable
    //Else, no

    
    /*
    this.sharedService.getTransactionId(this.policyNumber).subscribe((res) => {
      this.testLog.TransactionId = res.toString();
    });
    **/
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  importForms() {
    //If CPM Input exists initialize temp form list and parser to parse XML to JSON
    if (this.testLog.XmlData != null && this.testLog.XmlData.length > 1) {
      var tempFormList: any[] = [];
      var policyNumTemp;
      //Check for JSON CPM vs XML
      if (this.testLog.XmlData.startsWith("{")) {
        var s = this.testLog.XmlData;
        // preserve newlines, etc - use valid JSON
        s = s
          .replace(/\\n/g, "\\n")
          .replace(/\\'/g, "\\'")
          .replace(/\\"/g, '\\"')
          .replace(/\\&/g, "\\&")
          .replace(/\\r/g, "\\r")
          .replace(/\\t/g, "\\t")
          .replace(/\\b/g, "\\b")
          .replace(/\\f/g, "\\f");
        // remove non-printable and other non-valid JSON chars
        s = s.replace(/[\u0000-\u0019]+/g, "");
        var json = JSON.parse(s.trim());
        console.log(json);
        var forms: any;
        if (json.QuoteMessage != null) {
          forms = json.QuoteMessage.QuoteOptions[0].QuoteOption.Policy;
          this.policyNumber = json.QuoteMessage.QuoteMessage.QuoteOptions.QuoteOption[0].Policy.PolicyNumber;
        } else {
          forms = json.PolicyMessage.Policy.Form;
          this.policyNumber = json.Policy.PolicyMessage.Policy.PolicyNumber;
        }
        console.log(forms);
        for (var i = 0; i < forms.length; i++) {
          var form = new PolicyForm();
          form.FormName = forms[i].InternalFormNumber;
          tempFormList.push(form);
        }
      } else {
        var parser = new xml2js.Parser();
        parser.parseString(this.testLog.XmlData, function (err, result) {
          var forms: any;
          //Pull forms list into array var
          if (result.QuoteMessage != null) {
            forms =
              result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                .Policy[0].Forms[0].Form;
            
            //assign global policy number here, this.policynumber not working due to being inside callback function
              policyNumTemp =  result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
              .Policy[0].PolicyNumber;
          } else {
            forms = result.PolicyMessage.Policy[0].Forms[0].Form;
            policyNumTemp = result.PolicyMessage.Policy[0].PolicyNumber[0];
          }
          //Create form object and populate fields used in grid, then push to temp list
          for (var i = 0; i < forms.length; i++) {
            var form = new PolicyForm();
            form.FormName = forms[i].$.InternalFormNumber;
            tempFormList.push(form);
          }
        });
      }
      if (policyNumTemp != null && policyNumTemp != "") {
        this.policyNumber = policyNumTemp;
      }
      this.sharedService.getTransactionId(policyNumTemp, this.testLog.Environment).subscribe(res => {
        if (res != null) {
          console.log(res);
          this.testLog.TransactionId = res.toString();
        }
      });

      //Create policy form object and iterate through keys to populate all fields with default value (needed to set global list since model does not have default constructor)
      var obj = new PolicyForm();
      let keys = Object.keys(obj);
      let def = keys.reduce((result, key) => {
        result[key] = "";
        return result;
      }, {});
      let result = tempFormList.map((item) => ({ ...def, ...item }));
      this.formResults = result;
      // this.selectedFormList = result;
      //this.canCreateTest = false;
      // this.setSelectedRows();
      /*
      this.sharedService.getTransactionId(policyNumTemp, this.testLog.Environment).subscribe(res => {
        if (res != null) {
          console.log(res);
          this.testLog.TransactionId = res.toString();
        }
      });
      */
    } else {
      this.toastr.error("Data input required. (CPM/CCM/GBS)", "Missing Info", {
        positionClass: "toast-bottom-right",
      });

      //Get the transaction id from extension if exists
      /*
      this.sharedService.getTransactionId(policyNumTemp, this.testLog.Environment).subscribe(res => {
        if (res != null) {
          console.log(res);
          this.testLog.TransactionId = res.toString();
        }
      });
      */
      return;
    }
  }
  //Parse CPM and populate relevant metadata, then create LOB and coverage lists from data
  private populateCpmData(): void {
    this.diagnosticService
      .getCpmDataForTestCase(this.testLog.XmlData)
      .subscribe(
        (res) => {
          this.cpmMetadata.LOBs = res.ElementValues.LineOfBusiness;
          this.cpmMetadata.Coverages = res.ElementValues.Coverage;
          this.cpmMetadata.WritingCompany = res.ElementValues.WritingCompany[0];
          this.cpmMetadata.State = res.ElementValues.State[0];
          this.cpmMetadata.PolicyEffectiveDate = res.EffectiveDate;
          this.cpmMetadata.CPM = this.testLog.XmlData;

          // JSON
          if (!library) var library: any = {};

          library.json = {
            replacer: function (match, pIndent, pKey, pVal, pEnd) {
              var key = "<span class=json-key>";
              var val = "<span class=json-value>";
              var str = "<span class=json-string>";
              var r = pIndent || "";
              if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
              if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
              return r + (pEnd || "");
            },
            prettyPrint: function (obj) {
              var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
              return JSON.stringify(obj, null, 3)
                .replace(/&/g, "&amp;")
                .replace(/\\"/g, "&quot;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(jsonLine, library.json.replacer);
            },
          };

          if (this.cpmMetadata.CPM.startsWith("<")) {
            this.cpmIsXML = true;
          } else {
            this.jsonStringcopy = JSON.parse(
              JSON.stringify(this.testLog.XmlData)
            );
            this.cpmMetadata.CPM = JSON.parse(this.testLog.XmlData);
            this.cpmMetadata.CPM = library.json.prettyPrint(
              this.cpmMetadata.CPM
            );
            document.getElementById("JSONtestLogCPM").innerHTML =
              this.cpmMetadata.CPM;
            this.cpmIsXML = false;
          }

          this.createLOBAndCoverageLists();
        },
        (error) => {
          if (!library) var library: any = {};

          library.json = {
            replacer: function (match, pIndent, pKey, pVal, pEnd) {
              var key = "<span class=json-key>";
              var val = "<span class=json-value>";
              var str = "<span class=json-string>";
              var r = pIndent || "";
              if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
              if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
              return r + (pEnd || "");
            },
            prettyPrint: function (obj) {
              var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
              return JSON.stringify(obj, null, 3)
                .replace(/&/g, "&amp;")
                .replace(/\\"/g, "&quot;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(jsonLine, library.json.replacer);
            },
          };
          if (this.testLog.XmlData.startsWith("<")) {
            this.cpmIsXML = true;
          } else {
            this.cpmMetadata.CPM = JSON.parse(this.testLog.XmlData);
            this.cpmMetadata.CPM = library.json.prettyPrint(
              this.cpmMetadata.CPM
            );
            document.getElementById("JSONtestLogCPM").innerHTML =
              this.cpmMetadata.CPM;
            this.cpmIsXML = false;
          }
        }
      );
  }

  private createLOBAndCoverageLists(): void {
    this.lobResults = "";
    this.coverageResults = "";
    for (var lob of this.cpmMetadata.LOBs) {
      this.lobResults = this.lobResults + lob + ", ";
    }
    this.lobResults = this.lobResults.trim();
    this.lobResults = this.lobResults.substr(0, this.lobResults.length - 1);

    for (var coverage of this.cpmMetadata.Coverages) {
      this.coverageResults = this.coverageResults + coverage + ", ";
    }
    this.coverageResults = this.coverageResults.trim();
    this.coverageResults = this.coverageResults.substr(
      0,
      this.coverageResults.length - 1
    );
  }

  copyErrorText(): void {
    if (this.cpmIsXML) {
      this.clipboard.copy(this.testLog.XmlData);
    }
    else {
      this.clipboard.copy(this.jsonStringcopy);
    }

  }

  openTestSuite(): void {
    this.activeModal.close("Test");
  }

  onEnvChange(e : any) {
    this.dataService.onEnvChange(e);
  }

  generateDocuments() {
    this.dataService.mapAndAssembleMultiple();
  }

  openExtension() {
    if (this.testLog.Environment != "PROD") {
      window.open(`https://extension-web-${this.testLog.Environment.toLowerCase()}.azurewebsites.net/?transactionId=${this.testLog.TransactionId.toUpperCase()}&canEdit=false`);
    }
    else {
      window.open(`https://ghostdraft-extension.azurewebsites.net/?transactionId=${this.testLog.TransactionId.toUpperCase()}&canEdit=false`);
    }
  }
}

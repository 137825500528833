<div class="modal-header">
  <h4 class="modal-title">Generation Log Details</h4>
</div>

<div class="modal-body col-12">
  <mat-tab-group class="vex-tabs">
    <mat-tab label="Transaction details">
      <div class="row" style="padding-bottom: 20px">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <mat-list>
                <mat-list-item>
                  <p matLine class="detail-label gen-log-label">Action Type</p>
                  <p matLine class="gen-log-details">
                    {{ this.testLog.ActionType }}
                  </p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label gen-log-label">Timestamp</p>
                  <p matLine class="gen-log-details">
                    {{ this.testLog.TimeStamp }}
                  </p>
                </mat-list-item>
                <mat-list-item>
                  <p matLine class="detail-label gen-log-label">
                    Lines of Business
                  </p>
                  <p matLine class="gen-log-details">{{ this.lobResults }}</p>
                </mat-list-item>
                <mat-list-item style="overflow: auto; height: 100px">
                  <p matLine class="detail-label gen-log-label">Coverages</p>
                  <p
                    matLine
                    class="gen-log-details"
                    style="white-space: initial; height: 75px; overflow: auto"
                  >
                    {{ this.coverageResults }}
                  </p>
                </mat-list-item>
              </mat-list>
            </div>
            <div class="col-md-4">
              <div
                class="row"
                style="margin-left: 9px; max-height: 50px; margin-top: 20px"
              >
              <div class="log-label">Forms List In Request</div>
              </div>
              <div
                style="
                  display: block;
                  /* border-radius: 5px; */
                  /* margin-top: 10px;
                  margin-left: 10px; */
                  padding-left: 14px;
                  overflow: auto;
                  height: 250px;
                  width: 70%;
                  border-right: 1px solid #c0c0c07d;
                  --blink-scrollbar-width: 6px;
                "
              >
                <div class="row" *ngFor="let item of this.testLog.Forms">
                  <div
                    class="col-12"
                    id="execute-rules-content-heading-container"
                  >
                    <label class="label_heading log-label-form"
                      >&#x2022; {{ item }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                style="padding-bottom: 1px; margin-left: 9px; margin-top: 20px"
                class="gen-log-label"
              >
              <div class="log-label">Forms From CPM</div>
              </div>
              <div
                style="
                  display: block;
                  /* border-radius: 5px; */
                  /* margin-top: 10px;
                  margin-left: 10px; */
                  padding-left: 14px;
                  overflow: auto;
                  height: 250px;
                  width: 70%;
                  border-right: 1px solid #c0c0c07d;
                  --blink-scrollbar-width: 6px;
                "
              >
                <div class="row" *ngFor="let item of this.formResults">
                  <div
                    class="col-12"
                    id="execute-rules-content-heading-container"
                  >
                    <label class="label_heading log-label-form"
                      >&#x2022; {{ item.FormName }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- CPM -->

    <mat-tab label="CPM">
      <div class="row">
        <div class="col-md-12 mrgbtm10">
          <pre class="custom-xml" *ngIf="cpmIsXML" style="color: black !important">
    
                  {{ cpmMetadata.CPM | xml }}</pre>
          <!-- <pre *ngIf="!cpmIsXML">{{ cpmMetadata.CPM | jsonPipe }}</pre> -->
        <pre class="custom-xml" *ngIf="!cpmIsXML" id="JSONtestLogCPM">{{testLog.XmlData}}</pre>
    
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Compare Documents">
      <app-compare></app-compare>
    </mat-tab>
  </mat-tab-group>
  
</div>

<div class="modal-footer">
  <div class="col-md-3" style="margin-right: auto;">
    <label class="label_heading" style="margin-right: 5px;">Environment:</label>
    <div class="selectdiv select_wrapinline" style="max-width: 50%;">
      <label>
        <ng-select class="custom-select" appendTo="body" [items]="dataService.envList" bindLabel="Name" bindValue="ID" (change)="onEnvChange($event)"
                   [(ngModel)]="selectedEnv">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.Name }}">{{ item.Name }}</div>
          </ng-template>
        </ng-select>
      </label>
    </div>
  </div>

  <button mat-raised-button
          class="mat_btn_hov_act mrgrgt10"
          (click)="this.generateDocuments()"
          color="primary">
    Generate Documents
  </button>
  <button mat-raised-button
          class="mat_btn_hov_act mrgrgt10"
          (click)="this.openExtension()"
          *ngIf="this.testLog.ActionType.includes('Book') && this.testLog.TransactionId"
          color="primary">
    Open in Extension
  </button>
  <button mat-raised-button
          class="mat_btn_hov_act mrgrgt10"
          (click)="this.openTestSuite()"
          color="primary">
    Open Log in Test Suite
  </button>
  <button mat-raised-button
          class="mat_btn_hov_act mrgrgt10"
          (click)="this.copyErrorText()"
          color="primary">
    Copy CPM to Clipboard
  </button>
  <button mat-raised-button
          class="mat_btn_hov_act"
          (click)="activeModal.close()">
    Close
  </button>
</div>
